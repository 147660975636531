.course-of-illness-calendar-view {
  background: #282c34;

  .alert {
    p:last-child {
      margin-bottom: 0;
    }

    hr,
    p {
      margin: .5em 0;
    }
  }

  .callendar-day {
    font-size: 1.5em;
    font-weight: lighter;
  }

  td {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .callendar-week {
    font-size: 1.5em;
    // font-weight: bold;
  }

  .today {
    // background: red;
    border: 2px solid red !important;
  }

  .btn-group {
    margin-bottom: 1em;
  }
}
